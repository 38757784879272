<template>
  <div :key="settingsComponent">
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10" class="d-flex align-items-center">
        <h3>
          {{ $t('settings.project.corporateIdentity.name') }}
        </h3>
        <b-dropdown
            class="context-menu"
            toggle-class="dot-button"
            no-caret
            no-flip
            right
        >
          <template #button-content>
            <b-icon icon="three-dots-vertical" aria-hidden="true" scale="1"></b-icon>
          </template>
          <b-dropdown-item @click="copySettings">
            {{ $t('dropdowns.copySettings') }}
          </b-dropdown-item>
          <b-dropdown-item
            v-if="settingsCopied"
            @click="pasteSettings"
          >
            {{ $t('dropdowns.pasteSettings') }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col cols="12" offset-sm="1" sm="10">
        <hr>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.project.corporateIdentity.logoLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
          :image-object="corporateIdentity.logo.image"
          @upload-image-object="updateUploadLogoImageObject"
        />
        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
        <b-form-text v-html="$t('settings.project.corporateIdentity.logoDesc')"></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.project.corporateIdentity.faviconLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <image-upload
          :image-object="corporateIdentity.favicon.image"
          @upload-image-object="updateUploadFaviconImageObject"
          :accepted-file-types="'image/x-icon,image/vnd.microsoft.icon,'+
          'image/ico,image/icon,text/ico,application/ico'"
          :file-poster-height="'44px'"
          :allowImageEdit="false"
          :allowImageFocalpointEdit="false"
        />
        <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
        <b-form-text v-html="$t('settings.project.corporateIdentity.faviconDesc')"></b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.corporateIdentity.colors') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker :color="corporateIdentity._customColor[0]" @update-color="updateBaseColor"/>
          <label class="pl-3">
            {{ $t('settings.project.corporateIdentity.baseColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.project.corporateIdentity.baseColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3"></b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker
            :color="corporateIdentity._customColor[1]"
            @update-color="updateSecondColor"/>
            <label class="pl-3">
              {{ $t('settings.project.corporateIdentity.secondColorLabel') }}
            </label>
        </div>
        <b-form-text>
          {{ $t('settings.project.corporateIdentity.secondColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3"></b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker
            :color="corporateIdentity._customColor[2]"
            @update-color="updateThirdColor"
          />
          <label class="pl-3">
            {{ $t('settings.project.corporateIdentity.thirdColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.project.corporateIdentity.thirdColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3"></b-col>
      <b-col cols="12" sm="7">
        <div class="d-flex align-items-center">
          <color-picker
            :color="corporateIdentity._customColor[3]"
            @update-color="updateFourthColor"
          />
          <label class="pl-3">
            {{ $t('settings.project.corporateIdentity.fourthColorLabel') }}
          </label>
        </div>
        <b-form-text>
          {{ $t('settings.project.corporateIdentity.fourthColorDesc') }}
        </b-form-text>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>
          {{ $t('settings.project.corporateIdentity.typographyLabel') }}
        </label>
      </b-col>
      <b-col cols="12" sm="7">
        <!-- Font sections -->
        <div v-for="(font, index) in fonts.slice(0, maxFonts)" :key="index" class="position-relative mb-4">
          <label class="mb-0">
            {{ $t(`settings.project.corporateIdentity.font${index + 1}Label`) }}
          </label>
          <b-form-text class="pb-3">
            {{ $t(`settings.project.corporateIdentity.font${index + 1}Desc`) }}
          </b-form-text>
          <b-form-group v-slot="{ ariaDescribedbyFont }">
            <b-form-radio
              v-model="font.source"
              class="mb-1 text-uppercase"
              :aria-describedby="ariaDescribedbyFont"
              :name="`font${index + 1}Source`"
              value="googleFonts"
              @change="changeFontSource('googleFonts', index + 1)"
            >
              {{ $t('settings.project.corporateIdentity.useGoogleFonts') }}
            </b-form-radio>
            <b-form-radio
              v-model="font.source"
              class="mb-1 text-uppercase"
              :aria-describedby="ariaDescribedbyFont"
              :name="`font${index + 1}Source`"
              value="systemFonts"
              @change="changeFontSource('systemFonts', index + 1)"
            >
              {{ $t('settings.project.corporateIdentity.useSystemFonts') }}
            </b-form-radio>
            <b-form-radio
              v-if="customGroupFonts.length > 0"
              v-model="font.source"
              class="mb-1 text-uppercase"
              :aria-describedby="ariaDescribedbyFont"
              :name="`font${index + 1}Source`"
              value="customFonts"
              @change="changeFontSource('customFonts', index + 1)"
            >
              {{ $t('settings.project.corporateIdentity.useCustomFonts') }}
            </b-form-radio>
          </b-form-group>
          <div class="d-flex align-items-center">
            <div v-if="font.source === 'customFonts'" style="width: 200px;">
              <b-form-select
                v-model="font.font"
                :options="customGroupFonts"
                @change="changeFont($event, index + 1)"
              ></b-form-select>
            </div>
            <div v-if="font.source === 'systemFonts'" style="width: 200px;">
              <b-form-select
                v-model="font.font"
                :options="fontFamilyListSystem"
                @change="changeFont($event, index + 1)"
              ></b-form-select>
            </div>
            <font-picker
              v-if="font.source === 'googleFonts'"
              :id="`font-picker-${index + 1}`"
              :ref="`font-picker-${index + 1}`"
              class="mb-2"
              :api-key="'AIzaSyCBBJElCVDbMFMth4hmBGIAzDp5bO8ozWY'"
              :active-font="font.font"
              @change="changeFont($event, index + 1)"
            >
            </font-picker>
            <b-button v-if="index > 0 && fonts.length > 1"
                      variant="outline-danger"
                      size="sm"
                      class="ml-2 mb-2"
                      @click="removeFont(index)">
              {{ $t('settings.project.corporateIdentity.removeFont') }}
            </b-button>
          </div>
        </div>

        <!-- Add more fonts button -->
        <b-button
          v-if="fonts.length < maxFonts"
          variant="outline-primary"
          @click="addMoreFonts"
        >
          {{ $t('settings.project.corporateIdentity.addMoreFonts') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3"></b-col>
      <b-col cols="12" sm="7">
        <div class="mb-4">
          <b-form-checkbox
            id="enable-typo"
            v-model="corporateIdentity.enableExtendTypo"
            name="enableExtendTypo"
            size="lg"
            :value="true"
            :unchecked-value="false"
            @change="enableDisableExtendTypo"
          >
            {{ $t('settings.project.corporateIdentity.typography.enableExtendTypoLabel') }}
          </b-form-checkbox>
          <b-form-text>
            {{ $t('settings.project.corporateIdentity.typography.enableExtendTypoDec') }}
          </b-form-text>
        </div>
        <div v-if="corporateIdentity.enableExtendTypo" class="typography-box">
          <h3 class="mb-2" @click="openTypographyModal('h1')">
            Headline 1 (h1)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h2')">
            Headline 2 (h2)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h3')">
            Headline 3 (h3)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h4')">
            Headline 4 (h4)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h5')">
            Headline 5 (h5)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('h6')">
            Headline 6 (h6)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('p')">
            Paragraph (p)
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('customTypo1')">
            Custom Typo 1
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('customTypo2')">
            Custom Typo 2
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
          <h3 class="mb-2" @click="openTypographyModal('customTypo3')">
            Custom Typo 3
            <span class="d-inline-block">
              <b-icon icon="gear-fill" aria-hidden="true" scale=".8" variant="secondary"></b-icon>
            </span>
          </h3>
        </div>
        <modal-typography-settings
          v-if="showTypographyModal"
          :tag="tagForTypography"
          :fonts="fonts"
          :current-selected-family="familyOfExtendedTypoTag(tagForTypography)"
          :typography-styles="typographyStyles"
          @update-styles="updateStyles"
        />
      </b-col>
    </b-row>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="3">
        <label>{{ $t('settings.project.corporateIdentity.buttonLayoutLabel') }}</label>
      </b-col>
      <b-col cols="12" sm="7">
        <div>
          <div style="height: 50px;">
            <b-button :style="corporateIdentity.buttons.styleObject">Button</b-button>
          </div>
          <b-form-group v-slot="{ ariaDescribedbyShape }">
            <b-form-radio-group
              id="radio-shape-options"
              v-model="corporateIdentity.buttons.layout.buttonShape"
              :options="selectedButtonShapeOptions"
              :aria-describedby="ariaDescribedbyShape"
              name="radio-shape-options"
              @change="buttonShapeChanged($event, 'buttonShape')"
            ></b-form-radio-group>
          </b-form-group>
          <b-form-group v-slot="{ ariaDescribedbyStyle }">
            <b-form-radio-group
              id="radio-style-options"
              v-model="corporateIdentity.buttons.layout.buttonStyle"
              :options="selectedButtonStyleOptions"
              :aria-describedby="ariaDescribedbyStyle"
              name="radio-style-options"
              @change="buttonShapeChanged($event, 'buttonStyle')"
            ></b-form-radio-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import WebFontLoader from '@/vendor/webfontloader';
import FontPicker from 'font-picker-vue';

export default {
  name: 'ProjectSettingsCorporateIdentity',
  components: {
    ModalTypographySettings: () => import('@/components/modals/ModalTypographySettings.vue'),
    ImageUpload: () => import('@/components/helper/ImageUpload.vue'),
    ColorPicker: () => import('@/components/helper/ColorPicker.vue'),
    FontPicker,
  },
  props: [
    'corporateIdentityData',
    'isSysAdmin',
    'customGroupFonts',
  ],
  data: () => ({
    fonts: [
      { font: '', source: 'googleFonts' },
    ],
    logoFile: [],
    corporateIdentity: {
      logo: {
        image: {
          url: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
          width: null,
          height: null,
        },
      },
      favicon: {
        image: {
          url: null,
        },
      },
      _customColor: ['', '', '', ''],
      defaultFonts: {
        font1: {
          font: 'Open Sans',
          type: 'google',
        },
      },
      enableExtendTypo: false,
      extendTypo: {
        h1: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '2.4rem',
            lineHeight: '1.2',
            fontWeight: '200',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '2.0rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '2.0rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h2: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '2rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1.8rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1.8rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h3: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1.5rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1.3rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1.3rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h4: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1.17rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1.17rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1.17rem',
            lineHeight: '1.2',
            fontWeight: '600',
            letterSpacing: '0px',
          },
        },
        h5: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '0.83rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '0.83rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '0.83rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        h6: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '0.67rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '0.67rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '0.67rem',
            lineHeight: '1.2',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        p: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        customTypo1: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        customTypo2: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
        customTypo3: {
          fontFamily: 'Open Sans',
          screen: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          tablet: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
          mobile: {
            fontSize: '1rem',
            lineHeight: '1.5',
            fontWeight: '400',
            letterSpacing: '0px',
          },
        },
      },
      buttons: {
        layout: {
          buttonShape: 'round',
          buttonStyle: 'narrow',
        },
        styleObject: {
          borderRadius: '0.25rem',
          padding: '0.3125rem 1rem',
        },
      },
    },
    selectedButtonShape: '',
    selectedButtonShapeOptions: [
      { text: 'Round', value: 'round' },
      { text: 'Square', value: 'square' },
    ],
    selectedButtonStyle: '',
    selectedButtonStyleOptions: [
      { text: 'Narrow', value: 'narrow' },
      { text: 'Wide', value: 'wide' },
    ],
    typographyStyles: null,
    showTypographyModal: false,
    tagForTypography: null,
    devices: [
      'screen',
      'tablet',
      'mobile',
    ],
    typographyElements: [
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'p',
      'customTypo1',
      'customTypo2',
      'customTypo3',
    ],
    settingsCopied: false,
    settingsComponent: 42,
    fontFamilyListSystem: [
      'Arial',
      'Arial Black',
      'Verdana',
      'Tahoma',
      'Trebuchet MS',
      'Impact',
      'Times New Roman',
      'Didot',
      'Georgia',
      'American Typewriter',
      'Andalé Mono',
      'Courier',
      'Lucida Console',
      'Monaco',
      'Bradley Hand',
      'Brush Script MT',
      'Luminari',
      'Comic Sans MS',
    ],
  }),
  created() {
    if (this.corporateIdentityData) {
      this.setupLogoAndFavicon();
      this.setupColors();
      this.setupTypography();
      this.setupExtendedTypo();
      this.setupButtons();
      if (localStorage.getItem('corp_id_settings')) {
        this.settingsCopied = true;
      }
    }
  },
  computed: {
    maxFonts() {
      if (this.isSysAdmin) {
        return 5;
      }
      return 2;
    },
  },
  methods: {
    setupLogoAndFavicon() {
      if (this.corporateIdentityData.logo?.image) {
        this.corporateIdentity.logo.image = this.corporateIdentityData.logo.image;
      }
      if (this.corporateIdentity.logo?.image.url) {
        if (!this.$checkForCloudImage(this.corporateIdentity.logo.image.url)) {
          this.corporateIdentity.logo.image.url = this.$makeCloudImage(
            this.corporateIdentity.logo.image.url,
            { width: 450, height: 300 },
            this.corporateIdentity.logo.image,
          );
        }
      }
      this.corporateIdentity.favicon.image = (
        this.corporateIdentityData.favicon?.image
      )
        ? this.corporateIdentityData.favicon.image
        : this.corporateIdentity.favicon.image;
    },
    setupTypography() {
      if (this.corporateIdentityData.defaultFonts) {
        this.corporateIdentity.defaultFonts = { ...this.corporateIdentityData.defaultFonts };
        this.fonts = Object.keys(this.corporateIdentityData.defaultFonts)
          .map((key) => {
            if (this.corporateIdentityData.defaultFonts[key]) {
              return {
                font: this.corporateIdentityData.defaultFonts[key].font,
                source: this.setFontSource(this.corporateIdentityData.defaultFonts[key].type),
              };
            }
            return null;
          }).filter((f) => f !== null);
      } else if (this.corporateIdentityData._customFonts && this.corporateIdentityData._customFonts.google) {
        const googleFonts = this.corporateIdentityData._customFonts.google;
        this.fonts = googleFonts
          .filter((font) => font)
          .map((font) => ({
            font,
            source: 'googleFonts',
          }));

        // Add fonts to defaultFonts object
        this.corporateIdentity.defaultFonts = this.fonts.reduce((acc, font, index) => {
          acc[`font${index + 1}`] = {
            font: font.font,
            type: 'google',
          };
          return acc;
        }, {});
      }
      // Load fonts from Google only
      const googleFonts = this.fonts
        .filter((f) => f.source === 'googleFonts')
        .map((f) => f.font)
        .filter((f) => f);

      if (googleFonts.length > 0) {
        WebFontLoader.load({
          google: {
            families: googleFonts,
          },
        });
      }
    },
    setupExtendedTypo() {
      this.corporateIdentity.enableExtendTypo = !!this.corporateIdentityData.enableExtendTypo;
      if (this.corporateIdentityData.extendTypo) {
        this.typographyElements.forEach((element) => {
          if (this.corporateIdentityData.extendTypo[element]) {
            // eslint-disable-next-line max-len
            this.corporateIdentity.extendTypo[element].fontFamily = this.corporateIdentityData.extendTypo[element].fontFamily;
            this.devices.forEach((device) => {
              this.corporateIdentity.extendTypo[element][device] = {
                fontSize: this.corporateIdentityData.extendTypo[element][device].fontSize
                  || this.corporateIdentity.extendTypo[element].fontSize,
                lineHeight: this.corporateIdentityData.extendTypo[element][device].lineHeight
                  || this.corporateIdentity.extendTypo[element][device].lineHeight,
                fontWeight: this.corporateIdentityData.extendTypo[element][device].fontWeight
                  || this.corporateIdentity.extendTypo[element][device].fontWeight,
                letterSpacing: this.corporateIdentityData.extendTypo[element][device].letterSpacing
                  || this.corporateIdentity.extendTypo[element][device].letterSpacing,
              };
            });
          }
        });
      }
    },
    setupColors() {
      for (let i = 0; i < 4; i++) {
        // eslint-disable-next-line no-underscore-dangle
        this.corporateIdentity._customColor[i] = (
          this.corporateIdentityData._customColor
          && typeof this.corporateIdentityData._customColor[i] !== 'undefined'
        )
          ? this.corporateIdentityData._customColor[i]
          : '';
      }
    },
    setupButtons() {
      if (this.corporateIdentityData.buttons) {
        // eslint-disable-next-line max-len
        this.corporateIdentity.buttons.layout.buttonShape = this.corporateIdentityData.buttons.layout.buttonShape;
        // eslint-disable-next-line max-len
        this.corporateIdentity.buttons.layout.buttonStyle = this.corporateIdentityData.buttons.layout.buttonStyle;
        this.corporateIdentity.buttons.styleObject = this.corporateIdentityData.buttons.styleObject;
      }
    },
    openTypographyModal(tag) {
      this.typographyStyles = this.corporateIdentity.extendTypo[tag];
      this.tagForTypography = tag;
      this.showTypographyModal = true;
    },
    updateStyles(styles, tag) {
      this.showTypographyModal = false;
      this.tagForTypography = null;
      if (styles !== false) {
        // update the ci settings and emit
        this.corporateIdentity.extendTypo[tag] = styles;
        this.$emit('update-ci-settings', this.corporateIdentity);
      } else {
        console.log('cancel modal');
      }
    },
    buttonShapeChanged(value, type) {
      this.corporateIdentity.buttons.layout[type] = value;
      if (type === 'buttonStyle' && value === 'wide') {
        this.corporateIdentity.buttons.styleObject.padding = '0.5rem 1.6rem';
      } else if (type === 'buttonStyle' && value === 'narrow') {
        this.corporateIdentity.buttons.styleObject.padding = '0.3125rem 1rem';
      } else if (type === 'buttonShape' && value === 'round') {
        this.corporateIdentity.buttons.styleObject.borderRadius = '0.25rem';
      } else if (type === 'buttonShape' && value === 'square') {
        this.corporateIdentity.buttons.styleObject.borderRadius = '0';
      }
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateUploadLogoImageObject(imageObject) {
      this.corporateIdentity.logo.image = (imageObject === null)
        ? { url: null }
        : imageObject;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateUploadFaviconImageObject(imageObject) {
      this.corporateIdentity.favicon.image = (imageObject === null)
        ? { url: null }
        : imageObject;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateBaseColor(newColor) {
      this.corporateIdentity._customColor[0] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateSecondColor(newColor) {
      this.corporateIdentity._customColor[1] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateThirdColor(newColor) {
      this.corporateIdentity._customColor[2] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    updateFourthColor(newColor) {
      this.corporateIdentity._customColor[3] = newColor;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    enableDisableExtendTypo(event) {
      if (event === true) {
        // change the font to the first font
        const typographyElement = this.corporateIdentity.extendTypo;
        Object.keys(typographyElement).forEach((element) => {
          this.corporateIdentity.extendTypo[element].fontFamily = this.fonts[0].font;
        });
      }
      this.corporateIdentity.enableExtendTypo = event;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    changeFont(event, fontIndex) {
      const fontId = `font${fontIndex}`;
      let newFont = '';
      let type = '';

      switch (this.fonts[fontIndex - 1].source) {
        case 'systemFonts':
          newFont = event;
          type = 'system';
          break;
        case 'customFonts':
          newFont = event;
          type = 'custom';
          break;
        default:
          newFont = event.family;
          type = 'google';
          WebFontLoader.load({
            google: {
              families: [newFont],
            },
          });
      }

      const oldFont = this.corporateIdentity.defaultFonts[fontId]?.font;
      const typographyElement = this.corporateIdentity.extendTypo;

      // Check if the old font exists more than once in the default fonts
      const oldFontOccurrences = Object.values(this.corporateIdentity.defaultFonts)
        .filter((font) => font.font === oldFont).length;

      if (
        oldFont
        && this.corporateIdentity.enableExtendTypo === true
        && JSON.stringify(typographyElement).search(oldFont) !== -1
        && oldFontOccurrences < 2
      ) {
        this.showConfirmBoxForProjects(oldFont, newFont, type, fontIndex);
      } else {
        this.updateFont(fontIndex, newFont, type);
      }
    },
    changeFontSource(source, fontIndex) {
      const fontId = `font${fontIndex}`;
      let newFont = '';
      let type = '';

      if (source === 'customFonts') {
        type = 'custom';
        [newFont] = this.customGroupFonts;
      } else if (source === 'systemFonts') {
        type = 'system';
        newFont = 'Verdana';
      } else {
        type = 'google';
        newFont = 'Open Sans';
        WebFontLoader.load({
          google: {
            families: [newFont],
          },
        });
      }

      const oldFont = this.corporateIdentity.defaultFonts[fontId]?.font;
      const typographyElement = this.corporateIdentity.extendTypo;

      // Check if the old font exists more than once in the default fonts
      const oldFontOccurrences = Object.values(this.corporateIdentity.defaultFonts)
        .filter((font) => font.font === oldFont).length;

      if (
        oldFont
        && this.corporateIdentity.enableExtendTypo === true
        && JSON.stringify(typographyElement).search(oldFont) !== -1
        && oldFontOccurrences < 2
      ) {
        this.showConfirmBoxForProjects(oldFont, newFont, type, fontIndex);
      } else {
        this.updateFont(fontIndex, newFont, type);
      }
    },
    updateFont(fontIndex, newFont, type) {
      const fontId = `font${fontIndex}`;
      this.fonts[fontIndex - 1].font = newFont;
      this.corporateIdentity.defaultFonts[fontId] = {
        font: newFont,
        type,
      };
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    addMoreFonts() {
      if (this.fonts.length < this.maxFonts) {
        this.fonts.push({ font: 'Open Sans', source: 'googleFonts' });
        this.updateDefaultFonts();
      }
    },
    removeFont(index) {
      if (this.fonts.length > 1) {
        const removedFont = this.fonts[index].font;
        // Check if the removed font still exists in the remaining fonts
        const fontOccurrences = this.fonts.filter((font) => font.font === removedFont).length;
        if (fontOccurrences < 2) {
          // If the font is no longer in the list, show the confirmation dialog
          const newFont = this.fonts[0].font; // Use the first remaining font as the new font
          const newFontType = this.getFontType(this.fonts[0].source);
          // Check if the removed font is also a fontFamily in the extendedTypo object
          const isFontInExtendedTypo = Object.values(this.corporateIdentity.extendTypo)
            .some((element) => element.fontFamily === removedFont);

          if (!isFontInExtendedTypo) {
            // If the font is not in extendedTypo, proceed with removal
            this.fonts.splice(index, 1);
            this.updateDefaultFonts();
          } else if (!this.corporateIdentity.enableExtendTypo) {
            // Replace fontFamily in extendedTypography with the new font if it matches the old font
            Object.keys(this.corporateIdentity.extendTypo).forEach((key) => {
              if (this.corporateIdentity.extendTypo[key].fontFamily === removedFont) {
                this.corporateIdentity.extendTypo[key].fontFamily = newFont;
              }
            });
            this.fonts.splice(index, 1);
            this.updateDefaultFonts();
          } else {
            // If the font is in extendedTypo, show the confirmation dialog
            this.showConfirmBoxForProjects(removedFont, newFont, newFontType, index, true);
          }
        } else {
          this.fonts.splice(index, 1);
          // If the font still exists, just update the default fonts
          this.updateDefaultFonts();
        }
      }
    },
    updateDefaultFonts() {
      this.corporateIdentity.defaultFonts = this.fonts.reduce((acc, font, index) => {
        acc[`font${index + 1}`] = {
          font: font.font,
          type: this.getFontType(font.source),
        };
        return acc;
      }, {});
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    getFontType(source) {
      switch (source) {
        case 'systemFonts':
          return 'system';
        case 'customFonts':
          return 'custom';
        default:
          return 'google';
      }
    },
    familyOfExtendedTypoTag(tag) {
      return this.corporateIdentity.extendTypo[tag].fontFamily;
    },
    showConfirmBoxForProjects(oldFont, newFont, newFontType, fontIndex, isFontRemoved = false) {
      const h = this.$createElement;
      const titleVNode = h('div', { domProps: { innerHTML: this.$t('settings.project.corporateIdentity.typography.confirmTitle') } });
      const messageVNode = h('div', {
        class: ['text-center', 'p-4'],
        domProps: { innerHTML: this.$t('settings.project.corporateIdentity.typography.confirmContent') },
      });
      this.$bvModal.msgBoxConfirm([messageVNode], {
        title: [titleVNode],
        size: 'sm',
        buttonSize: 'sm',
        okTitle: this.$t('buttons.ok'),
        cancelTitle: this.$t('buttons.cancel'),
        footerClass: 'flex-nowrap',
        hideHeaderClose: false,
        centered: true,
      })
        .then((value) => {
          const fontId = `font${fontIndex}`;
          if (value) {
            WebFontLoader.load({
              google: {
                families: [newFont],
              },
            });
            const typographyElement = this.corporateIdentity.extendTypo;
            Object.keys(typographyElement).forEach((element) => {
              // check if the old font exist in the array
              if (typographyElement[element].fontFamily === oldFont) {
                this.corporateIdentity.extendTypo[element].fontFamily = newFont;
              }
            });
            if (!isFontRemoved) {
              this.updateFont(fontIndex, newFont, newFontType);
            } else {
              this.fonts.splice(fontIndex, 1);
              this.updateDefaultFonts();
            }
          } else {
            // cancel the modal reset the radio button and reset the dropdown
            const oldType = this.corporateIdentity.defaultFonts[fontId].type;
            this.fonts[fontIndex - 1].font = oldFont;
            this.fonts[fontIndex - 1].source = this.setFontSource(oldType);
            this.updateDefaultFonts();
          }
        })
        .catch((err) => {
          // An error occurred
          console.log(err);
        });
    },
    copySettings() {
      localStorage.setItem('corp_id_settings', JSON.stringify(this.corporateIdentity));
      this.settingsCopied = true;
    },
    pasteSettings() {
      const newSettings = localStorage.getItem('corp_id_settings');
      this.corporateIdentity = JSON.parse(newSettings);
      this.fonts = Object.keys(this.corporateIdentity.defaultFonts)
        .map((key) => ({
          font: this.corporateIdentity.defaultFonts[key].font,
          source: this.setFontSource(this.corporateIdentity.defaultFonts[key].type),
        }));
      this.settingsComponent += 1;
      localStorage.removeItem('corp_id_settings');
      this.settingsCopied = false;
      this.$emit('update-ci-settings', this.corporateIdentity);
    },
    setFontSource(type) {
      if (!type) return null;
      switch (type) {
        case 'system':
          return 'systemFonts';
        case 'custom':
          return 'customFonts';
        default:
          return 'googleFonts';
      }
    },
  },
};
</script>

<style scoped lang="scss">
.verte {
  justify-content: flex-start;
}

#font-picker-one {
  z-index: 3;
}

#font-picker-two {
  z-index: 2;
}

#app #content .typography-box {
  h3 {
    color: $settings-form-text;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    cursor: pointer;

    span {
      svg {
        position: relative;
        top: 1px;
      }
    }
  }
}

/deep/ .modal-dialog .modal-footer > button {
  display: block;
  width: 100%;

  &:first-child {
    border-right: 1px solid #e0e2e4 !important;
  }
}

/deep/ .btn.dot-button {
  color: #b9b9b9;
  background: transparent;
  border: none;
  margin-bottom: 0.5rem;

  &:hover {
    color: #fff;
  }
}

.font-picker,
#font-picker-3,
#font-picker-4,
#font-picker-5 {
  z-index: 3;
}
</style>
